export const invalidEmail = (email) => {
    if (!email || typeof email !== 'string') return false;
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) return false;
    return true;
};

// 8~20 characters and mix alphabet and numbers
export const invalidPassword = (string) => {
    if (!string || typeof string !== 'string') return true;
    if (/^[A-Za-z]{7,19}$/i.test(string) || parseInt(string, 10).toString().length === string.length || string.length < 8 || string.length > 20) return true;
    return false;
};

// the same character more than 4 times
export const isContinuousString = (string) => {
    if (!string || typeof string !== 'string') return true;
    if (/(\w)\1\1\1/.test(string)) return true;
    return false;
};