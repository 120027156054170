
import React from 'react';
import styled from 'styled-components/macro';

import IconSearch from '../icons/Search';
import { palette } from '../../defines/styles';

const Button = styled.button`
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #969696;
    text-transform: lowercase;
    border: 0;
    background: transparent;

    &:hover:not(:disabled) {
        color: ${palette.green.dark};
    }

    span {
        color: ${palette.green.dark};
    }

    svg {
        vertical-align: middle;
        margin-right: 5px;
        margin-bottom: 1px;
        g {
            fill: ${palette.green.dark};
        }
    }

    &:disabled {
        color: ${palette.point.gray};
        span {
            color: ${palette.point.gray};
        }
        svg g {
            fill: ${palette.point.gray};
        }
    }
`;

const ExplorerButton = props => (
    <Button type="button" {...props}><IconSearch /><span>e</span>xplore</Button>
);

export default ExplorerButton;