import React from 'react';
import styled from 'styled-components/macro';

import IconArrowCircle from '../icons/ArrowCircle';
import { palette } from '../../defines/styles';

const animteScrollTo = function(_selector, _duration, _adjust) {
    const selector = _selector || 'body';
    const targetEle = document.querySelector(selector);
  
    // - Get current &amp; target positions
    const scrollEle = document.documentElement || window.scrollingElement;
    const currentY = scrollEle.scrollTop;
    const targetY = targetEle.offsetTop;// - (_adjust || 0);
    animateScrollTo(currentY, targetY, _duration);
  
    // - Animate and scroll to target position
    function animateScrollTo(_startY, _endY, _duration) {
        _duration = _duration || 600;
        const unitY = (targetY - currentY) / _duration;
        const startTime = new Date().getTime();
        const endTime = new Date().getTime() + _duration;

        const scrollTo = function() {
            const now = new Date().getTime();
            const passed = now - startTime;
            if (now <= endTime) {
                scrollEle.scrollTop = currentY + (unitY * passed);
                requestAnimationFrame(scrollTo);
            } else {
                // When selector is body, there is bug, that can be not positioned to 0 scrollY.
                if (selector === 'body' && window.scrollY > 0) {
                    window.scrollTo(0, 0);
                }
            }
        };
        requestAnimationFrame(scrollTo);
    }
};

const Button = styled.button`
    opacity: ${props => (props.isVisible ? '1' : '0')};
    pointer-events: ${props => (props.isVisible ? 'fill' : 'none')};
    transition: opacity 0.2s ease-out;
    width: 32px;
    height: 32px;
    padding: 5px;
    border: 0;
    background: transparent;
    &:hover:not(:disabled),
    &:active:not(:disabled) {
        svg g {
            fill: #00d7d0;
        }
    }
    &:disabled {
        svg circle {
            fill: ${palette.gray.light};
        }
    }
`;

const MoveTopButton = (props) => (
    <Button type="button" {...props} onClick={e => {
        if (props.onClick) props.onclick(e);
        animteScrollTo();
    }}>
        <IconArrowCircle />
    </Button>
);

export default MoveTopButton;