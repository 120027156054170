import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { os } from '@mirinae/js-utils/utils/userAgent';

import IconThumbsDown from '../icons/ThumbsDown';
import IconThumbsDownFill from '../icons/ThumbsDownFill';
import IconThumbsUp from '../icons/ThumbsUp';
import IconThumbsUpFill from '../icons/ThumbsUpFill';

const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    color: #979797;
    font-size: 12px;

    svg {
        margin-right: 3px;
    }
    & + & {
        margin-left: 15px;
    }
`;

const ThumbsButton = (props) => {
    const [hover, setHover] = useState(false);

    return (
        <Button type="button" {...props} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            {props.disabled ? props.theme === 'up' ? <IconThumbsUp color="#C4C4C4" /> : <IconThumbsDown color="#C4C4C4" />
            : hover && os.isOthers ? props.theme === 'up' ? <IconThumbsUpFill /> : <IconThumbsDownFill />
            : props.theme === 'up' ? <IconThumbsUp color="#979797" /> : <IconThumbsDown color="#979797" />}
            {props.children}
        </Button>
    );
};

export default ThumbsButton;