import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { palette } from '../../defines/styles';
import { KeyboardToggleBody, KeyboardToggleHandle } from '../icons/KeyboardToggle';

const Text = styled.span`
    margin-top: -1px;
    padding: 0 5px;
    font-weight: 400;
    white-space: nowrap;
`;
const hoverIconStyle = `
    svg:first-child {
        path {
            fill: ${palette.green.middle};
        }
        rect {
            stroke: ${palette.green.middle};
        }
    }
    svg:last-child {
        circle {
            fill: ${palette.green.middle};
        }
    }
`;
const KeyboardIcons = styled.span`
    position: relative;
    width: 32px;
    height: 15px;

    svg:last-child {
        position: absolute;
        top: 0;
        left: ${props => props.state ? '17px' : '0'};
        transition: all 0.1s ease-out;
    }
    ${props => props.state && (`
        ${hoverIconStyle};
    `)}
`;
const ButtonToggleWrapper = styled.div`
    display: flex;
    height: 15px;
    font-size: 13px;
    font-weight: 500;
    ${props => (!props.disabled && 'cursor: pointer')};

    ${props => props.hover && (`
        color: ${palette.green.middle};
        ${KeyboardIcons} {
            ${hoverIconStyle};
        }
    `)}
`;

const ToggleKeyboardButton = props => {
    const [state, setState] = useState(props.state ? props.state : false);
    const [hover, setHover] = useState(false);

    return (
        <ButtonToggleWrapper
            disabled={props.disabled}
            id={props.id}
            className="toggle-keyboard-button"
            hover={hover}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            onClick={() => {
                setState(!state);
                if (props.onClick) props.onClick(!state);
            }}
        >
            <KeyboardIcons state={state}>
                <KeyboardToggleBody /><KeyboardToggleHandle />
            </KeyboardIcons>
            {!state && <Text>{props.children}</Text>}
        </ButtonToggleWrapper>
    );
};

export default ToggleKeyboardButton;