import React from 'react';
import styled from 'styled-components/macro';

import IconNext from '../icons/Next';

const Button = styled.button`
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    text-transform: capitalize;
    padding: 0;
    border: 0;
    background: transparent;

    &:disabled:not(:hover) {
        color: #DFDFDF;
        svg {
            filter: grayscale(1);
        }
    }

    &:hover {
        svg {
            transform: rotateX(180deg);
        }
    }

    svg {
        margin-left: 10px;
    }
`;

const BackButton = props => (
    <Button
        type="button"
        {...props}
    >
        {props.children}
        <IconNext />
    </Button>
);

export default BackButton;