import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { UserContext, useUserState, authAPI } from '@mirinae/react-auth';
import { UrlParamContext, useUrlParamContext } from '@mirinae/shared/contexts/urlParams';
import { KeyboardContext, useKeyboardContext } from '@mirinae/shared/contexts/keyboard';
import { FeatureFlagContext, useFeatureFlags } from '@mirinae/shared/contexts/featureFlags';

import { cookieDomain, UserAgent } from '@mirinae/js-utils';
import { classes } from '@mirinae/react-ui';
import { FB_PIXELID } from '@mirinae/defines/keys';
import { apiPaths, appRoots, wikinaePaths } from '@mirinae/defines/paths';
import { useAppVersionStoreMethods, useAppVersionStore } from "@mirinae/stores/appVersion";
import { httpAPI } from '@explorer/modules/apis/http';
import GlobalStyling from '@mirinae/defines/GlobalStyling';
import LoadingSpinner from '@mirinae/react-ui/components/spinner/LoadingSpinner';

// note that we can even do dynamic loads on individual user-events:
// button.addEventListener('click', event => {
//   import('./someModule').then(module => {
//     module.default();
//   });
// });

const Base = (props) => {
    const userState = useUserState();
    const [userContext, setUserContext] = userState;
    const keyboardState = useKeyboardContext();
    const featureFlags = useFeatureFlags();
    const urlParams = useUrlParamContext(featureFlags);
    const cookieState = useCookies(['landed']);
    const [testSite, setTestSite] = useState(false);
    const checkedLanding = useRef(false);
    const redirectToLanding = useRef(false);
    const _isAppChecked = useRef(false);
    const { setVersionData } = useAppVersionStoreMethods();
    const { os } = UserAgent;

    let isApp = useAppVersionStore(state => state.isApp);

    if (!_isAppChecked.current) {
        // check if app, derive version as float & check various version-dependent enablements
        _isAppChecked.current = true;

        const setAppParameters = (versionParam = '0') => {
            // app status determined, version determined, derive various flags & store
            let isProEnabledVersion = !isApp; // web or mobile-web always pro-enabled
            let isPricePlan3PlusEnabled = !isApp;
            let isAdMobAdsEnabled =  false; // true; // false; // for testing!! s/b false;
            let appVersion = 0;

            if (isApp) {
                // derive float rep for version
                appVersion = versionParam.match(/^[0-9.]+\/*$/) ? versionParam.split('.').reduce((a, v, i) => a + (Number.parseInt(v, 10) || 0) / 10 ** i, 0) : 0;
                // derive version-dependent enablements
                isProEnabledVersion = appVersion >= 1.54;
                isPricePlan3PlusEnabled = appVersion >= 2.16;
                isAdMobAdsEnabled = appVersion >= 2.2;
                window.versionParam = versionParam;

                if (appVersion <= 2.15) {
                    // hack for <= v2.1.5 mobile apps, need to see a '.' following 'wikinae' to condition wikinae screens correctly
                    appRoots.wikinae = 'wikinae.';
                    for (const path in wikinaePaths) {
                        wikinaePaths[path] = wikinaePaths[path].replace('wikinae', 'wikinae.').replace(/\.$/, './');
                    }
                }

                if (isAdMobAdsEnabled && window.flutter_inappwebview) {
                    // create AdMob ad units ahead of time
                    httpAPI('', apiPaths.listActiveAdUnits, { method: 'GET' })
                        .then(response => {
                            const { adUnits = [] } = response.data;
                            for (const adUnit of adUnits) {
                                for (const type in adUnit) {
                                    console.log('call CreateAd', type, adUnit[type]);
                                    window.flutter_inappwebview.callHandler('CreateAd', { type, unitId: adUnit[type] });
                                }
                            }
                        });
                }
            }

            setTestSite(window.location.host.match(/^(alpha|local|mirinae\.dev)/));

            console.log('isApp', isApp, 'appVersion', appVersion, 'isProEnabledVersion', isProEnabledVersion, 'isPricePlan3PlusEnabled', isPricePlan3PlusEnabled, 'isAdMobAdsEnabled', isAdMobAdsEnabled);
            setVersionData({ isAppChecked: true, isApp, versionParam, appVersion, isProEnabledVersion, isPricePlan3PlusEnabled, isAdMobAdsEnabled, displayMustUpgradeApp: null });

            if (!checkedLanding.current && userContext.user && (!userContext.user.role || userContext.user.role === 'guest')) {
                checkedLanding.current = true;
                redirectToLanding.current = false;
                const [cookies, setCookie] = cookieState;
                console.log('check landing, disable redirectToLanding first', cookies.landed, window.location.pathname);
                // if (!cookies.landed && !window.location.href.match(/\/(lessons\/player\/)|(explorer\/.*(text=|bm=))|(wikinae\/entry\/)|(landing\/)/)) {
                if (!cookies.landed) {
                    if (window.location.pathname === '/') {
                        console.log('   not yet landed, redirectToLanding');
                    //     redirectToLanding.current = true;
                    // } else {
                        setCookie('landed', 'true', { path: '/', maxAge: 10 * 365 * 24 * 60 * 60, domain: cookieDomain });
                        if (!isApp)
                            window.location.href = 'landing';
                    }
                }
            }
       }

        const fallbackCheck = () => {
            // older app, no GetAppVersion support, check for window.appVersion & URL v= query params
            console.log('fallbackCheck');
            const versionParam = new URLSearchParams(window.location.search).get('v')?.replace('/', '') || window.appVersion;
            isApp = (os.isAndroid || os.isIos) && (Boolean(versionParam) || window.inAppView);
            setAppParameters(versionParam);
        }

        // latest apps will tell us version, should have been in apps from the start!!
        if (window.flutter_inappwebview) {
            console.log('window.flutter_inappwebview true');
            isApp = true;
            window.flutter_inappwebview.callHandler('GetAppVersion', { })
                .then(versionParam => setAppParameters(versionParam))
                .catch(() => fallbackCheck());
        } else {
            // not in an app, fill in default values, complete other checks
            fallbackCheck();
        }
    }

    useEffect(() => {
        // ReactPixel.init(FB_PIXELID, advancedMatching, options);
        setUserContext(prev => ({ ...prev, homePath: '/' }));

        if (os.isIos || os.isAndroid) {
            document.body.classList.add(classes.mobile);
        } else {
            document.body.classList.add(classes.desktop);
        }
    }, []);

    useEffect(() => {
        authAPI.setupUserContext(userState, cookieState);
    }, []);

    return userContext.user && userContext.user.role ? (
        <>
            <GlobalStyling />
            <UserContext.Provider value={userState}>
                <FeatureFlagContext.Provider value={featureFlags}>
                    <UrlParamContext.Provider value={urlParams}>
                        <KeyboardContext.Provider value={keyboardState}>
                                { testSite && (
                                    <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 99999, padding: '5px 8px', background: 'rgba(255,0,0,.2)', fontSize: '12px', color: '#b45656',
                                    }}>{testSite[1]} site, {isApp ? `app v${window.versionParam}` : 'web'}
                                    </div>
                                )}
                            <Suspense fallback={LoadingSpinner}>
                                { redirectToLanding.current && window.location.pathname !== 'landing' ? (
                                    <Routes>
                                        <Route path="*" element={<Navigate to={'landing'} />} />
                                    </Routes>
                                ) : (
                                    props.children
                                )}
                            </Suspense>
                        </KeyboardContext.Provider>
                    </UrlParamContext.Provider>
                </FeatureFlagContext.Provider>
            </UserContext.Provider>
        </>
    ) : null;
};

// remove console.log on product mode
// if (process.env.NODE_ENV === 'production' && !window.location.hostname.match(/^alpha\./)) {
//     console.log = () => {};
// }

export const withBase = (Component) => {
    const inner = (props) => (
        <Base>
            <Component {...props} />
        </Base>
    )

    return inner;
}

export default Base;
