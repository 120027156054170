import React from 'react';
import styled from 'styled-components/macro';

import IconInstagram from '../icons/InstagramMini';
import IconFacebook from '../icons/FacebookMini';
import IconYoutube from '../icons/YoutubeMini';
import IconTwitter from '../icons/TwitterMini';

const IconWrapper = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    padding: 0;
    border: 0;
    background: transparent;

    svg path {
        fill: #8b8b8b;
    }

    ${props => props.theme === 'f' && (`
        &:hover {
            svg path {
                fill: #4460a0;
            }
        }
    `)}

    ${props => props.theme === 'i' && (`
        &:hover {
            .outside {
                fill: url(#o426y1z9ea);
            }
            .dot {
                fill: url(#nw4u5lt81b);
            }
        }
    `)}

    ${props => props.theme === 'y' && (`
        &:hover {
            svg path {
                fill: #ea4335;
            }
        }
    `)}

    ${props => props.theme === 't' && (`
        &:hover {
            svg path {
                fill: #4dc8f1;
            }
        }
    `)}
`;

const SNSIconLink = props => {
    switch (props.theme) {
    case 'facebook':
        return (
            <IconWrapper theme="f" href={props.link} target="_blank" rel="noreferrer">
                <IconFacebook />
            </IconWrapper>
        );
    case 'instagram':
        return (
            <IconWrapper theme="i" href={props.link} target="_blank" rel="noreferrer">
                <IconInstagram />
            </IconWrapper>
        );
    case 'twitter':
        return (
            <IconWrapper theme="t" href={props.link} target="_blank" rel="noreferrer">
                <IconTwitter />
            </IconWrapper>
        );
    case 'youtube':
        return (
            <IconWrapper theme="y" href={props.link} target="_blank" rel="noreferrer">
                <IconYoutube />
            </IconWrapper>
        );
    default:
        return null;
    }
};

export default SNSIconLink;