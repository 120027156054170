/* eslint-disable linebreak-style */
const permissions = (name, callback, errorCallback) => {
    if (navigator.permissions !== undefined) {
        navigator.permissions
            .query({ name })
            .then((permissionStatus) => {
                /*
                    prompt : init state
                    granted : user allowed
                    denied : user denied
                */
                callback(permissionStatus.state);
            })
            .catch(error => {
                console.log(error);
                // Samsung browser dosen't support some enum(camera, microphone, and so on...)
                if (callback && /SamsungBrowser/i.test(window.navigator.userAgent)) {
                    callback('prompt');
                }
                if (errorCallback)
                    errorCallback(error);
            });
    } else {
        // Use session storage to the not supported browser
        const permissionStatus = window.sessionStorage.getItem(name);
        if (permissionStatus === null) {
            callback('prompt');
        } else {
            callback(permissionStatus);
        }
    }
};

export { permissions };