// /*
//  * Global keyboard (history, reference search form)
//  */
import { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

export const KeyboardContext = createContext({});

// initializing input state
// const inputStateInit = {
//     useVirtualKeyboard: 'false',
//     show: false,
//     sourceText: '',
// };

export function useKeyboardContext() {
    const [cookies, setCookies] = useCookies(['useVirtualKeyboard']);
    const [state, setState] = useState({
        useVirtualKeyboard: cookies.useVirtualKeyboard === 'true',
        show: false,
        sourceText: '',
    });

    const updateInputState = (update) => {
        setState((prev) => ({ ...prev, ...update }));
        if (update.useVirtualKeyboard !== undefined) {
            setCookies('useVirtualKeyboard', update.useVirtualKeyboard ? 'true' : false);
        }
    };

    // InputContext value
    return {
        ...state,
        update: updateInputState,
    };
}
