import { createGlobalStyle } from 'styled-components/macro';

import 'react-custom-scroll/dist/customScroll.css';
import { fontkoKR, fontDefault, fontDefaultAlt, palette, classes, breakPoint } from '@mirinae/react-ui';
import { wikinaePalette } from '@mirinae/wikinae/modules/defines/styles';
import { os } from '@mirinae/js-utils/utils/userAgent';

import ImgHelp from '@mirinae/explorer/components/svg/Help.svg';
import ImgCommentDots from '@mirinae/explorer/components/svg/CommentDots.svg';
import { lessonsBreakPoint } from '@mirinae/lessons/modules/defines/styles';
import { UserAgent } from '@mirinae/js-utils';

const nameByUserAgent = UserAgent.os.isOthers ? 'desktop' : 'mobile';

// global-styling shared by all the apps
//  app-specific styles should be placed inside a class and that class defined on the root element for the app, as with .explorer below
const GlobalStyling = createGlobalStyle`
  
    html {
        min-height: 100vh;
    }
    body {
        height: -webkit-fill-available;
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: 100%;
        min-height: 100vh;
        margin: 0;
        
        // for members...
        &#auth-page #members-app-root,
        &#promotion-page #members-app-root,
        &#myaccount-page #members-app-root,
        &#cancel-page #members-app-root {
            background-color: #F6FBFB;
        }
        &#payment-page #members-app-root {
            overflow-x: hidden;
            background-color: ${palette.pro.default};
        }
    }
    [lang='ja'] body {
        font-family: "Noto Sans JP", sans-serif;
    }
    [lang='vi'] body {
        font-family: verdana;
    }
    [lang='th'] body {
        font-family: "SF Pro TH","SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
    [lang='zh'] body {
        font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
    }
    // explorer-specific styles (HEY!! check for common declarations here with other apps!) - note the :where() here retains a single selectors priority
    :where(#explorer-app-root, #lyric-explorer-app-root, #lyric-explore-home) {
        font-weight: 500;
        font-family: ${fontDefault};
        color: ${palette.point.black};
        background-color: #f6fbfb;
        &.has-parseTree {
            background-color: #fff;
        }
        &#reference-page.${classes.desktop},
        &#exploration.${classes.desktop}:not(.tutorial) {
            overflow-y: scroll;
        }
        &#kpop {
            background: #F6F2FF url(/assets/members/lyrics/bg-kpop-body-${os.isOthers ? 'desktop' : 'mobile'}.jpg) repeat-y 50%/cover;
        }
    
        button {
            padding: 0;
            border: 0;
            border-radius: 0;
            background: transparent;
            cursor: pointer;
            font-weight: 500;
            font-family: inherit;
            color: inherit;
            pointer-events: fill;
    
            svg {
                pointer-events: none;
            }
    
            &:disabled {
                cursor: default;
            }
        }
    
        input[type='email'],
        input[type='text'],
        input[type='password'] {
            outline: 0;
            font-weight: 500;
            font-family: inherit;
            padding: 0;
            border-radius: 0;
        }
    
        // remove autofill background-color
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
        }
        
        textarea {
            resize: none;
            border: 0;
            border-radius: 0;
            padding: 0;
            background-color: transparent;
            font-family: inherit;
            color: inherit;
            font-weight: 500;
        }
        
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: inherit;
        }
        
        * {
            box-sizing: border-box;
        }
        
        *:focus {
            outline: 0;
        }
    
        svg {
            flex-shrink: 0;
        }
    
        a {
            pointer-events: fill;
            color: inherit;
            text-decoration: none;
    
            svg {
                pointer-events: none;
            }
        }
    
        p {
            padding: 0;
            margin: 0;
        }
    
        ul, ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    
        dl, dt, dd {
            padding: 0;
            margin: 0;
        }
    
        [lang='ko'] {
            font-family: ${fontkoKR};
        }
    
        [lang='en'] {
            font-family: ${fontDefault};
        }
    
        // prevent grammarly icon
        grammarly-extension {
            display: none !important;
        }
    
        // react-custom-scroll bar style
        .rcs-custom-scroll .rcs-custom-scrollbar {
            width: 2px !important;
            padding: 0 !important;
            // opacity: 1 !important;
        }
        .rcs-custom-scroll .rcs-inner-handle {
            height: 100% !important;
            margin-top: 0 !important;
            background-color: #d8d8d8 !important;
            border-radius: 1px !important;
        }
        .display, .hide { // GNB scroll
            .rcs-custom-scroll .rcs-custom-scrollbar {
                right: 0;
            }
            .rcs-custom-scroll .rcs-inner-container {
                padding: 15px 15px 0;
            }
        }
    
        // spinner
        .lds-orbitals {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            box-sizing: initial;
        }
        .lds-orbitals * {
            --center: translate(-50%, -50%);
            box-sizing: initial;
        }
        .lds-orbitals .center {
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: var(--center);
        }
        .lds-orbitals .outer-spin,
        .lds-orbitals .inner-spin {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        .lds-orbitals .inner-arc {
            position: absolute;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .inner-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .inner-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(25deg);
        }
        .lds-orbitals .inner-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(17px, 0);
        }
        .lds-orbitals .inner-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(-17px, 0);
        }
        .lds-orbitals .inner-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .inner-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .outer-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg);
        }
        .lds-orbitals .outer-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .outer-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(32px, 0);
        }
        .lds-orbitals .outer-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(-32px, 0);
        }
        .lds-orbitals .outer-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-spin {
            animation: spin 2s linear infinite;
        }
        .lds-orbitals .inner-spin {
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
    
        // tutorial style
        .text-green {
            color: ${palette.green.middle};
        }
        .link-blue {
            color: ${palette.point.blue};
            font-weight: 500;
        }
        .icon-tut-help {
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url(${ImgHelp}) no-repeat;
            vertical-align: middle;
            margin: -3px 0 0;
            opacity: 0.65;
        }
        .icon-tut-comment {
            display: inline-block;
            width: 20px;
            height: 16px;
            background: url(${ImgCommentDots}) no-repeat;
            vertical-align: middle;
            margin: -3px 0 0;
            opacity: 0.65;
        }
    
        // new signup flow
        .${classes.mobile}#account-confirmed-page,
        .${classes.mobile}#signup-page,
        .${classes.mobile}#login-page {
            background-color: #FFF;
        }
        @media only screen and (max-width: ${breakPoint.common.md}) {
            #account-confirmed-page,
            #signup-page,
            #login-page {
                background-color: #FFF;
            }
        }
    }
    
    // members-specific styles (HEY!! check for common declarations here with other apps!) - note the :where() here retains a single selectors priority
    :where(#members-app-root) {
        font-weight: 500;
        font-family: ${fontDefault};
        color: ${palette.primary.black};
        background-color: ${palette.primary.white};
      
        button {
            padding: 0;
            border: 0;
            border-radius: 0;
            background: transparent;
            cursor: pointer;
            font-weight: 500;
            font-family: ${fontDefault};
            color: inherit;
            pointer-events: fill;
    
            svg {
                pointer-events: none;
            }
    
            &:disabled {
                cursor: default;
            }
        }
    
        input[type='email'],
        input[type='text'],
        input[type='password'] {
            outline: 0;
            font-weight: 500;
            font-family: ${fontDefault};
            padding: 0;
            border-radius: 0;
        }
    
        // remove autofill background-color
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
        }
        
        textarea {
            resize: none;
            border: 0;
            border-radius: 0;
            padding: 0;
            background-color: transparent;
            font-family: ${fontDefault};
            color: inherit;
            font-weight: 500;
        }
        
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
            font-weight: 500;
        }
        
        * {
            box-sizing: border-box;
        }
        
        *:focus {
            outline: 0;
        }
        
        a {
            pointer-events: fill;
            color: inherit;
            text-decoration: none;
    
            svg {
                pointer-events: none;
            }
        }
    
        p {
            padding: 0;
            margin: 0;
        }
    
        ul, ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    
        dl, dt, dd {
            padding: 0;
            margin: 0;
        }
    
        em {
            font-style: normal;
        }
    
        [lang='ko'] {
            font-family: ${fontkoKR};
        }
    
        [lang='en'] {
            font-family: ${fontDefault};
        }
    
        // prevent grammarly icon
        grammarly-extension {
            display: none !important;
        }
    
        // react-custom-scroll bar style
        .rcs-custom-scroll .rcs-custom-scrollbar {
            width: 2px !important;
            padding: 0 !important;
            // opacity: 1 !important;
        }
        .rcs-custom-scroll .rcs-inner-handle {
            height: 100% !important;
            margin-top: 0 !important;
            background-color: #d8d8d8 !important;
            border-radius: 1px !important;
        }
        .display, .hide { // GNB scroll
            .rcs-custom-scroll .rcs-custom-scrollbar {
                right: 0;
            }
            .rcs-custom-scroll .rcs-inner-container {
                padding: 15px 15px 0;
            }
        }
    
        // spinner
        .lds-orbitals {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            box-sizing: initial;
        }
        .lds-orbitals * {
            --center: translate(-50%, -50%);
            box-sizing: initial;
        }
        .lds-orbitals .center {
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: var(--center);
        }
        .lds-orbitals .outer-spin,
        .lds-orbitals .inner-spin {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        .lds-orbitals .inner-arc {
            position: absolute;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .inner-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .inner-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(25deg);
        }
        .lds-orbitals .inner-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(17px, 0);
        }
        .lds-orbitals .inner-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(-17px, 0);
        }
        .lds-orbitals .inner-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .inner-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .outer-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg);
        }
        .lds-orbitals .outer-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .outer-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(32px, 0);
        }
        .lds-orbitals .outer-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(-32px, 0);
        }
        .lds-orbitals .outer-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-spin {
            animation: spin 2s linear infinite;
        }
        .lds-orbitals .inner-spin {
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
        
    }
    
    // wikinae global styles, refactor!!
    :where(#wikinae-app-root) {
        min-height: 100vh;
        min-width: 280px;
        font-family: ${fontDefault};
        font-weight: 500;
        color: ${wikinaePalette.primary.black};
        background: url(/assets/wikinae/images/bg-body.png) 50%;
    
        button {
            padding: 0;
            border: 0;
            border-radius: 0;
            background: transparent;
            cursor: pointer;
            font-weight: 500;
            font-family: ${fontDefault};
            color: inherit;
            pointer-events: fill;
    
            svg {
                pointer-events: none;
            }
    
            &:disabled {
                cursor: default;
            }
        }
    
        input[type='email'],
        input[type='text'],
        input[type='password'] {
            outline: 0;
            font-weight: 500;
            font-family: ${fontDefault};
            padding: 0;
            border-radius: 0;
        }
    
        // remove autofill background-color
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    
        textarea {
            resize: none;
            border: 0;
            border-radius: 0;
            padding: 0;
            background-color: transparent;
            font-family: ${fontDefault};
            color: inherit;
            font-weight: 500;
        }
    
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
            font-weight: 500;
        }
    
        * {
            box-sizing: border-box;
        }
    
        *:focus {
            outline: 0;
        }
    
        a {
            pointer-events: fill;
            color: inherit;
            text-decoration: none;
    
            svg {
                pointer-events: none;
            }
        }
    
        p {
            padding: 0;
            margin: 0;
        }
    
        ul, ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    
        dl, dt, dd {
            padding: 0;
            margin: 0;
        }
    
        em {
            font-style: normal;
        }
    
        svg {
            flex-shrink: 0;
        }
    
        // prevent grammarly icon
        grammarly-extension {
            display: none !important;
        }
    
        // react-slick
        .slick-slider {
            .slick-track {
                display: flex;
                gap: 0 10px;
            }
            .slick-prev,
            .slick-next {
                z-index: 2;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background: ${wikinaePalette.primary.black} no-repeat 50%;
                box-shadow: 0 5px 0 ${wikinaePalette.effect.shadow};
                transform: translateY(calc(-50% - 5px));
                &:before {
                    content: '';
                }
                svg {
                    width: 24px;
                    height: 24px;
                    path {
                        stroke: ${wikinaePalette.primary.white};
                    }
                }
                &:active:not(.slick-disabled) {
                    box-shadow: none;
                    transform: translateY(-50%);
                }
                &.slick-disabled {
                    background-color: ${wikinaePalette.effect.disabled};
                    box-shadow: none;
                }
                &:hover:not(.slick-disabled) {
                    box-shadow: none;
                    transform: translateY(5px);
                    transition: all 0.1s ease-in-out;
                }
            }
            .slick-prev {
                background-image: url(/assets/wikinae/images/arrow-left-button.png);
            }
            .slick-next {
                background-image: url(/assets/wikinae/images/arrow-right-button.png);
            }
            .slick-dots {
                display: flex !important;
                justify-content: center;
                height: 20px;
                bottom: 0;
                & li {
                    display: block;
                    margin: 0 4px;
                    & button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        &:before {
                            content: '';
                            position: static;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: ${wikinaePalette.primary.black};
                            opacity: 1;
                        }
                    }
                    &.slick-active {
                        & button:before {
                            background-color: ${wikinaePalette.primary.background};
                            border: 1.5px solid ${wikinaePalette.primary.black};
                        }
                    }
                }
            }
        }
    }
    
    :where(#lessons-app-root) {

        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        margin: 0;
        font-weight: 500;
        font-family: ${fontDefault};
        color: ${palette.point.black};

        &#reset-page {
           background: #F6FBFB;
        }
        &.${classes.desktop} #root {
           min-width: ${lessonsBreakPoint.lg};
           @media only screen and (max-width: ${lessonsBreakPoint.md}) {
              min-width: ${lessonsBreakPoint.md};
           }
           @media only screen and (max-width: ${lessonsBreakPoint.sm}) {
              min-width: ${lessonsBreakPoint.sm};
           }
        }
        &.${classes.mobile}:not(#auth-page) #root {
           min-height: ${window.innerHeight}px;
        }
        &.${classes.mobile}.has-skip #root > div {
           padding-bottom: 30px;
        }
        ${nameByUserAgent === 'desktop' && `
           &[class*='player-'] #root > div {
              min-width: 928px;
           }
        `}

        ${() => {
            const classes = [];
            ['beginner', 'intermediate', 'advanced', 'idiom', 'neologism', 'proverb'].forEach(group => {
                if (['beginner', 'intermediate', 'advanced'].indexOf(group) !== -1) {
                    classes.push(`
                      &.lesson-${group} {
                          background: linear-gradient(to bottom,#F6FBFB 0%,#F0F7F7 100%) fixed;
               
                          #root > div {
                              background: url('/assets/lessons/images/bg-lesson-${group}-${nameByUserAgent}.webp?blank=1') no-repeat ${nameByUserAgent === 'desktop' ? '50%' : '100%'} 0;
                          }
                      }
                  `);
                    ['start', 'lesson', 'special', 'mini', 'goal-check', 'end'].forEach(page => {
                        classes.push(`
                          &.player-${group}-${page} {
                              #root > div {
                                 background: url('/assets/lessons/images/bg-player-${group}-${page.match(/start|end|goal-check/) ? 'lesson' : page}-${nameByUserAgent}.webp?blank=1') no-repeat 100% 0/auto auto,
                                     url('/assets/lessons/images/bg-player-${page}-${nameByUserAgent}.jpg?blank=1') no-repeat 50% 0/cover !important;
                              }
                          }
                      `);
                    });
                } else {
                    classes.push(`
                      &.lesson-${group} {
                          background: linear-gradient(to bottom,#FCF9FF 0%,#E8EFF8 100%) fixed;
               
                          #root > div {
                              background: url('/assets/lessons/images/bg-lesson-${group}-${nameByUserAgent}.webp?blank=1') no-repeat ${nameByUserAgent === 'desktop' ? '50%' : '100%'} 0;
                          }
                      }
                  `);
                    ['start', 'lesson', 'special', 'goal-check', 'end'].forEach(page => {
                        classes.push(`
                          &.player-${group}-${page} {
                              #root > div {
                                 background: url('/assets/lessons/images/bg-player-${group}-${nameByUserAgent}.webp?blank=1') no-repeat 100% 0/auto auto,
                                     url('/assets/lessons/images/bg-player-culture-${page}-${nameByUserAgent}.jpg?blank=1') no-repeat 50% 0/cover !important;
                              }
                          }
                      `);
                    });
                }
            });
            return classes.join('\n');
        }}
        
        &.player-starter-introtohangul {
            #root > div {
                background: url('/assets/lessons/images/bg-player-starter-introtohangul-${nameByUserAgent}.png') 100% 0px / cover no-repeat !important;
            }
        }
        &.player-starter-hangul-start,&.player-starter-hangul-end {
            #root > div {
                background: url('/assets/lessons/images/bg-player-starter-start-1-${nameByUserAgent}.png') top right / auto no-repeat, 
                    url('/assets/lessons/images/bg-player-starter-start-1-${nameByUserAgent}.jpg') 100% 0px / cover no-repeat !important;
            }
        }
        &.player-starter-hangul {
            #root > div {
                background: url('/assets/lessons/images/bg-player-starter-${nameByUserAgent}.png') top right / auto no-repeat, 
                    url('/assets/lessons/images/bg-player-starter-${nameByUserAgent}.jpg') 100% 0px / cover no-repeat !important;
            }
        }
        &.player-starter-imageandtext,&.player-starter-imageandtext-start {
            #root > div {
                background: url('/assets/lessons/images/bg-player-intro-1-${nameByUserAgent}.png') bottom right / auto no-repeat, 
                    url('/assets/lessons/images/bg-player-intro-3-${nameByUserAgent}.png') top right / auto no-repeat, #F6FBFB !important;
            }
        }

        // see above styled-component
        button {
        //    padding: 0;
        //    border: 0;
        //    border-radius: 0;
        //    background: transparent;
        //    font-weight: 500;
        //    font-family: ${fontDefault};
        //    color: inherit;
            cursor: pointer;
            pointer-events: fill;
    
           svg {
                pointer-events: none;
           }
    
            &:disabled {
               cursor: default;
            }
        }
    
        input[type='email'],
        // input[type='text'],  see above styled-component
        input[type='password'] {
            outline: 0;
            font-weight: 500;
            font-family: ${fontDefault};
            padding: 0;
            border-radius: 0;
        }
    
        // remove autofill background-color
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    
        textarea {
            resize: none;
            border: 0;
            border-radius: 0;
            padding: 0;
            background-color: transparent;
            font-family: ${fontDefault};
            color: inherit;
            font-weight: 500;
        }
    
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
            font-weight: 500;
        }
    
        * {
            box-sizing: border-box;
        }
    
        *:focus {
            outline: 0;
        }
    
        a {
            pointer-events: fill;
            color: inherit;
            text-decoration: none;
    
            * {
               pointer-events: none;
            }
        }
    
        p {
            padding: 0;
            margin: 0;
        }
    
        ul, ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    
        dl, dt, dd {
            padding: 0;
            margin: 0;
        }
        svg {
            flex-shrink: 0;
        }
    
        [lang='ko'] {
            font-family: ${fontkoKR};
        }
    
        [lang='en'] {
            font-family: ${fontDefault};
        }
    
        // prevent grammarly icon
        grammarly-extension {
            display: none !important;
        }
    
        // react-custom-scroll bar style
        .rcs-custom-scroll .rcs-custom-scrollbar {
            width: 2px !important;
            padding: 0 !important;
        }
        .rcs-custom-scroll .rcs-inner-handle {
            height: 100% !important;
            margin-top: 0 !important;
            background-color: #d8d8d8 !important;
            border-radius: 1px !important;
        }
        .display, .hide { // GNB scroll
            .rcs-custom-scroll .rcs-custom-scrollbar {
               right: 0;
            }
            .rcs-custom-scroll .rcs-inner-container {
               padding: 15px 15px 0;
            }
        }
        
        // spinner
        .lds-orbitals {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            box-sizing: initial;
        }
        .lds-orbitals * {
            --center: translate(-50%, -50%);
            box-sizing: initial;
        }
        .lds-orbitals .center {
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: var(--center);
        }
        .lds-orbitals .outer-spin,
        .lds-orbitals .inner-spin {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        .lds-orbitals .inner-arc {
            position: absolute;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .inner-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .inner-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(25deg);
        }
        .lds-orbitals .inner-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(17px, 0);
        }
        .lds-orbitals .inner-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: var(--center) translate(-17px, 0);
        }
        .lds-orbitals .inner-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .inner-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid;
        }
        .lds-orbitals .outer-arc_start-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg);
        }
        .lds-orbitals .outer-arc_end-a {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg);
        }
        .lds-orbitals .outer-moon-a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(32px, 0);
        }
        .lds-orbitals .outer-moon-b {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            transform: var(--center) translate(-32px, 0);
        }
        .lds-orbitals .outer-arc_start-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(65deg) scale(-1, -1);
        }
        .lds-orbitals .outer-arc_end-b {
            border-color: transparent transparent transparent;
            transform: var(--center) rotate(45deg) scale(-1, -1);
        }
        .lds-orbitals .outer-spin {
            animation: spin 2s linear infinite;
        }
        .lds-orbitals .inner-spin {
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            100% {
               transform: rotate(360deg);
            }
        }
        
        /* for SVG layout calculations, used by main index.html, so need to be global.  
              NOTE: these should all be 10px font-size for scaling calculations to work */
        .lesson-korean-phrase {
            font-family: ${fontkoKR};
            font-size: 10px;
            font-weight: bold;
            white-space: pre;
        }
        .lesson-english-phrase {
            font-family: ${fontDefault};
            font-size: 10px;
            font-weight: 600;
            white-space: pre;
       }
        .lesson-pattern-subtext {
            font-family: ${fontDefaultAlt};
            font-size: 10px;
            font-weight: 500;
        }
        .lesson-message {
        
        }
        .lesson-annotation {
        
        }
    }
    
    :where(#landing-page) {
        #explorer-app-root {
            background-color: #fff;
        }
    }

    :where(#lyric-explorer-app-root, #lyric-explore-home) {
        background-color: #FDF8FF;
    }
`;

export default GlobalStyling;