export const localPaths = {
    home: '',
    lessons: '',
    admin: 'admin',
    landing: 'landing',
    mylessons: 'mylessons',
    player: 'player',
    active: 'active',
    complete: 'complete',
    starter: 'starter',
    beginner: 'beginner',
    intermediate: 'intermediate',
    advanced: 'advanced',
    idiom: 'idiom',
    neologism: 'neologism',
    proverb: 'proverb',
    features: 'features',
    overview: 'overview',
    question: 'question',
    course: 'course',
    summary: 'summary',
    onelesson: 'onelesson',
    search: 'search',
};
