import React, { useState } from 'react';
import styled from 'styled-components/macro';

import IconRepeat from '../icons/Repeat';
import { palette } from '../../defines/styles';

const Button = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: transparent;

    svg {
        vertical-align: middle;
    }

    ${props => props.disabled && (`
        path:first-child {
            fill: ${palette.point.gray};
        }
        path:last-child {
            fill: ${palette.gray.light};
        }
    `)}
`;

const RepeatButton = props => {
    const [hover, setHover] = useState(0);
    return (
        <Button
            type="button"
            onClick={e => props.onClick && props.onClick(e)}
            disabled={props.disabled}
            onMouseOver={() => setHover(1)} onMouseOut={() => setHover(0)}
            title={props.title}
        >
            <IconRepeat hover={hover} />
        </Button>
    );
};

export default RepeatButton;