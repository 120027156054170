import React from 'react';
import styled from 'styled-components/macro';

import IconMagnifier from '../icons/Magnifier';

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #87cbc7;
    border: 0;
    padding: 0;

    &:hover {
        background-color: #48a9a6;
    }
    &:disabled {
        background-color: #d8d8d8;
    }
    svg {
        margin: -2px -1px 0 0;
    }
`;

const ExploreCircleButton = props => (
    <Button type="button" {...props}>
        <IconMagnifier color='#fff' />
    </Button>
);

export default ExploreCircleButton;