/**
 * Strings - input params to string dynamically
 * @param {string, arg1, arg2, ..., argN} args
 * ('Hello, ${0}.', 'John') -> 'Hello, John'
 */

const strings = (...input) => {
    if (input.length < 2) return input[0];
    let result = input[0];
    for (let i = 1; i <= input.length; i += 1) {
        result = result.replace(`{${i - 1}}`, input[i]);
    }
    return result;
};

const replaceAll = (str, searchStr, replaceStr) => {
    return str.split(searchStr).join(replaceStr);
};

export { strings, replaceAll };