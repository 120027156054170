/*
 *    Copyright © 2020, Mirinae Corp. and John Wainwright
 */
import { createContext, useState } from 'react';

export const FeatureFlagContext = createContext();

export function useFeatureFlags() {
    // set up feature-flag context
    const flagState = useState({
        // current feature flags
        endOfPagePapagoTranslation: false,
        multiLineInputArea: true, // in current branches, multi-line is now the only node supported (see tag Single-line-input for prior version)
        incrementalAnalysis: false,
        persistentInputText: true, // this is inactive for now, persistent by default
        displayAllParseTrees: false,
        // don't forget to add new ones to the encoder/decoder below

        // metaflag, causes above flag states to be added to page URL for forcing settings by URL
        addToURL: false, // for now, always on, seems the best way to make these states persistent
    });

    return flagState;
}