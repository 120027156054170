import React from 'react';
import styled from 'styled-components/macro';

import IconShield from '../icons/Shield';
import { palette } from '../../defines/styles';

const Button = styled.button`
    display: block;
    width: 100%;
    color: ${props => (props.active ? palette.green.middle : palette.point.black)};
    font-weight: 500;
    text-align: left;
    font-size: 13px;
    font-family: inherit;
    padding: 0;
    border: 0;
    background: transparent;

    svg {
        vertical-align: middle;
        margin-right: 12px;
        margin-bottom: 3px;
        [lang='es'] & {
            margin-right: 4px;
        }
    }
    span {
        margin-left: 4px;
    }
`;

const ResetButton = props => (
    <Button type="button" {...props}>
        <IconShield />{props.children}
    </Button>
);

export default ResetButton;