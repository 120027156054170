import React from 'react';
import styled from 'styled-components/macro';

import IconFilter from '../icons/Filter';

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 0;
    padding: 0;
    :hover {
        svg path {
            fill: #48A9A6;
        }
    }
`;

const FilterButton = (props) => (
    <Button type="button" {...props}>
        <IconFilter color="#565656" />
    </Button>
);

export default FilterButton;