export const localPaths = {
    home: '',
    bookmark: 'bookmark',
    entries: 'entries',
    entry: 'entries',
    oldEntry: 'entry',
    history: 'history',
    profile: 'profile',
    myComments: 'comments',
    search: 'search',
};
