export const localPaths = {
    home: '',
    explorer: '',
    reference: 'reference',
    library: 'library',
    glossary: 'glossary',
    help: 'help',
    history: 'history',
    tutorial: 'tutorial',
    ocr: 'ocr',
    userFeedback: 'userFeedback',
    userfeedback: 'user-feedback',
    lessons: 'lessons', // redirect to lessons (legacy)
    landing: 'landing',
};
