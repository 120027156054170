import React from 'react';
import styled from 'styled-components/macro';

import BannerAppStore from '../icons/BannerAppStore';
import BannerPlayStore from '../icons/BannerPlayStore';

const StoreBannerWrapper = styled.a`
    ${props => props.style}
    svg {
        vertical-align: top;
    }
`;

const StoreBanner = (props) => (
    <StoreBannerWrapper
        href={props.link} target="_blank" style={props.style}
        onClick={e => props.onClick && props.onClick(e)}
    >
        {props.type === 'android' && <BannerPlayStore />}
        {props.type === 'ios' && <BannerAppStore />}
    </StoreBannerWrapper>
);

export default StoreBanner;