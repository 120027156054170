import React from 'react';

const Level6 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '45'} height={props.height || '16'} viewBox="0 0 45 16">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-640 -485) translate(640 485)">
                <rect width="45" height="16" fill={props.disabled ? '#DFDFDF' : '#48A9A6'} rx="8" />
                <text fill="#FFF" fontFamily="Montserrat-Medium, Montserrat" fontSize="11" fontWeight="400">
                    <tspan x="11" y="12">Lv. </tspan> <tspan x="28.358" y="12" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold">6</tspan>
                </text>
            </g>
        </g>
    </svg>
);

export default Level6;