import React from 'react';

const LevelNo = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '45'} height={props.height || '16'} viewBox="0 0 45 16">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-976 -489) translate(976 489)">
                <rect width="45" height="16" fill={props.disabled ? '#DFDFDF' : '#545454'} rx="8" />
                <text fill="#FFF" fontFamily="Montserrat-Medium, Montserrat" fontSize="11" fontWeight="400" letterSpacing="-.4">
                    <tspan x="7" y="12">No </tspan> <tspan x="24.676" y="12" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold">Lv.</tspan>
                </text>
            </g>
        </g>
    </svg>
);

export default LevelNo;