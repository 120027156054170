export const localPaths = {
    home: '',
    plan: 'plan',
    pro: 'plan',
    payment: 'payment',
    paymenttestpp: 'paymenttestpp',
    profile: 'profile',
    accountSetting: 'account-setting',
    subscription: 'subscription',
    cancelSubscription: 'cancel-subscription',
    promotion: 'promotion',
};
