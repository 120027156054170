import React from 'react';
import styled from 'styled-components/macro';

import IconLogout from '../icons/Logout';
import IconLogin from '../icons/Login';
import { palette } from '../../defines/styles';

const Button = styled.button`
    color: ${props => (props.disabled ? palette.point.gray : palette.point.black)};
    font-size: 13px;
    font-family: inherit;
    font-weight: inherit;
    white-space: nowrap;
    padding: 0;
    border: 0;
    background: transparent;

    &:hover:not(:disabled) {
        color: ${props => (props.theme === 'login' ? palette.green.middle : palette.point.orange)};
    }
    &:disabled {
        color: ${palette.point.gray};
        path:first-child {
            fill : ${palette.point.gray};
        }
        path:last-child {
            fill: ${palette.gray.light};
        }
    }
    svg {
        vertical-align: middle;
        margin: 0 9px 3px 0;
    }
`;
const Text = styled.span`
    display: inline-block;
    height: 16px;
    color: ${palette.point.black};
    font-size: 13px;
    span {
        font-size: 10px;
    }
    svg {
        vertical-align: middle;
        margin: 0 9px 3px 0;
    }
`;

const LoginButton = props => {
    if (props.type === 'text') {
        return (
            <Text>
                {props.theme === 'login' ? (
                    <><IconLogin />{props.children || <>Log In <span>with</span></>}</>
                ) : (
                    <><IconLogout />Log Out</>
                )}
            </Text>
        );
    } else {
        return (
            <Button type="button" {...props}>
                {props.theme === 'login' ? (
                    <><IconLogin />{props.children || 'Log In'}</>
                ) : (
                    <><IconLogout />{props.children || 'Log Out'}</>
                )}
            </Button>
        );
    }
};

export default LoginButton;