import React from 'react';
import styled from 'styled-components/macro';

import { palette, zIndex } from '../../defines/styles';

import IconPlanet from './IconPlanet';

const Background = styled.div`
    ${props => (props.theme === 'lessons' ? `
        position: fixed;
        z-index: ${zIndex.keyboard + 10};
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.3); 
    ` : `
        width: 100%;
        max-width: 1045px;
    `)}
`;

const BannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 25px 15px;
    
    background: ${props => (props.theme === 'lessons' ? palette.primary.white : '#F1F4F4')};
    border-radius: 15px;
    text-align: center;
    svg {
        margin-bottom: 8px;
    }
    strong {
        display: block;
        margin-bottom: 5px;
        color: ${palette.gray[1]};
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
    }
    p {
        margin-bottom: 20px;
        color: ${palette.primary.black};
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
    ${props => (props.theme === 'analyzer' && `
        margin-top: 70px;
        padding: 30px;
        justify-content: space-between;
        text-align: left;
        p {
            text-align: center;
        }
        @media only screen and (min-width: 530px) {
            svg {
                margin-right: 8px;
            }
        }
        @media only screen and (max-width: 980px) {
            flex-direction: column;
            text-align: center;
        }
    `)}
    ${props => props.theme === 'lessons' && `
        width: 350px;
        padding: 40px;
        @media only screen and (max-width: 390px) {
            width: calc(100% - 40px);
        }
        @media only screen and (max-width: 360px) {
            padding: 40px 20px;
        }
        svg {
            width: 36px;
            height: 36px;
            path {
                fill: #9FCCCC;
            }
        }
    `}
`;

const BannerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${props => (props.theme === 'analyzer' && `
        justify-content: center;
        @media only screen and (min-width: 530px) {
            flex-direction: row;
        }
    `)}
`;

const BannerLeft = styled.div`
    justify-content: center;
    ${props => (props.theme === 'analyzer' && `
        @media only screen and (max-width: 390px) {
            padding-right: 0;
            padding-bottom: 20px;
        }
    `)}
`;

const BannerRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    ${props => (props.theme === 'analyzer' && `
        @media only screen and (min-width: 530px) {
            flex-direction: row-reverse;
        }
    `)}
`;

const Button = styled.button`
    display: block;
    border: 0;
    width: ${props => (props.theme === 'lessons' ? '100%' : '160px')};
    height: 47px;
    padding: 0 20px;
    color: ${palette.primary.white};
    font-size: 14px;
    font-weight: ${props => (props.theme === 'lessons' ? '700' : '600')};
    font-family: inherit;
    background: ${palette.main.main};
    border-radius: 10px;
    white-space: nowrap;
    :hover {
        background: ${palette.main.darken[1]}
    }
    :disabled {
        background-color: ${palette.gray[6]};
    }
`;

const TextButton = styled.button`
    display: block;
    border: 0;
    margin-top: ${props => (props.theme === 'lessons' ? '30px' : '20px')};
    background: none;
    width: 100%;
    height: 25px;
    color:  ${palette.main.main};
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    :hover {
        color: ${palette.main.darken[1]}
    }
    ${props => (props.theme === 'analyzer' && `
        @media only screen and (min-width: 530px) {
            margin-top: 0;
            margin-right: 25px;
            border: 1px solid ${palette.main.main};
            border-radius: 10px;
            width: 160px;
            height: 47px;
        }
    `)}
`;

const OnlineSurveyBanner = ({ theme,
    localization = {
        "survey-banner-title": "Is Mirinae useful to you?",
        "survey-banner-desc": "We invite you to leave your opinion to help us improve and grow the service.",
        "survey-banner-button":	"Sure!",
        "survey-banner-cancel": "Maybe later"
    },
    onConfirm,
    onCancel }) => (
    <Background theme={theme}>
        <BannerWrapper theme={theme}>
            <BannerLeft theme={theme}>
                <BannerHeader theme={theme}>
                    <IconPlanet />
                    <strong>{localization['survey-banner-title']}</strong>
                </BannerHeader>
                <p>{localization['survey-banner-desc']}</p>
            </BannerLeft>
            <BannerRight theme={theme}>
                <Button type="button" theme={theme} onClick={onConfirm && onConfirm}>{localization['survey-banner-button']}</Button>
                <TextButton type="button" theme={theme} onClick={onCancel && onCancel}>{localization['survey-banner-cancel']}</TextButton>
            </BannerRight>
        </BannerWrapper>
    </Background>
);

export default OnlineSurveyBanner;