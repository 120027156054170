import React from 'react';

const LevelAll = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '105'} height={props.height ||'16'} viewBox="0 0 105 16">
        <g fill="none" fillRule="evenodd">
            <path fill={props.disabled ? '#DFDFDF' : '#545454'} d="M8 0h89c4.418 0 8 3.582 8 8s-3.582 8-8 8H8c-4.418 0-8-3.582-8-8s3.582-8 8-8z" transform="translate(-691 -465) translate(691 465)" />
            <text fill="#FFF" fontFamily="Montserrat-Medium, Montserrat" fontSize="11" fontWeight="400" transform="translate(-691 -465) translate(691 465)">
                <tspan x="35" y="12">All </tspan> <tspan x="52.149" y="12" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold">{props.state ? 'On' : 'Off'}</tspan>
            </text>
        </g>
    </svg>
);

export default LevelAll;