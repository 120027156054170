import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import RotationSpinner from './RotationSpinner';

const LoadingSpinnerWrapper = styled.div`
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
`;

const LoadingSpinner = (
    <LoadingSpinnerWrapper>
        <RotationSpinner color="#C5C5C5" />
    </LoadingSpinnerWrapper>
    // <div style={{ height: '205px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
)

export default LoadingSpinner;