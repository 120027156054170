import React from 'react';
import styled, { keyframes } from 'styled-components';

const transformRotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const SpinnerWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: ${props => props.size || '80px'};
    height: ${props => props.size || '80px'};
    box-sizing: initial;

    * {
        --center: translate(-50%, -50%);
        box-sizing: initial;
    }
    .center {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: var(--center);
    }
    .outer-spin,
    .inner-spin {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    .inner-arc {
        position: absolute;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        border: 3px solid;
    }
    .inner-arc_start-a {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(45deg);
    }
    .inner-arc_end-a {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(25deg);
    }
    .inner-moon-a {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: var(--center) translate(17px, 0);
    }
    .inner-moon-b {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: var(--center) translate(-17px, 0);
    }
    .inner-arc_start-b {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(65deg) scale(-1, -1);
    }
    .inner-arc_end-b {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(45deg) scale(-1, -1);
    }
    .outer-arc {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 3px solid;
    }
    .outer-arc_start-a {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(65deg);
    }
    .outer-arc_end-a {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(45deg);
    }
    .outer-moon-a {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transform: var(--center) translate(32px, 0);
    }
    .outer-moon-b {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transform: var(--center) translate(-32px, 0);
    }
    .outer-arc_start-b {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(65deg) scale(-1, -1);
    }
    .outer-arc_end-b {
        border-color: transparent transparent transparent;
        transform: var(--center) rotate(45deg) scale(-1, -1);
    }
    .outer-spin {
        animation: ${transformRotate} 2s linear infinite;
    }
    .inner-spin {
        animation: ${transformRotate} 1s linear infinite;
    }
`;

const RotationSpinner = ({ size, color = '#94D0CD' }) => (
    <SpinnerWrapper size={size} style={{ transform: 'scale(0.6)' }}>
        <div className="center" style={{ background: color }} />
        <div className="inner-spin">
            <div className="inner-arc inner-arc_start-a" style={{ borderColor: color }} />
            <div className="inner-arc inner-arc_end-a" style={{ borderColor: color }} />
            <div className="inner-arc inner-arc_start-b" style={{ borderColor: color }} />
            <div className="inner-arc inner-arc_end-b" style={{ borderColor: color }} />
            <div className="inner-moon-a" style={{ background: color }} />
            <div className="inner-moon-b" style={{ background: color }} />
        </div>
        <div className="outer-spin">
            <div className="outer-arc outer-arc_start-a" style={{ borderColor: color }} />
            <div className="outer-arc outer-arc_end-a" style={{ borderColor: color }} />
            <div className="outer-arc outer-arc_start-b" style={{ borderColor: color }} />
            <div className="outer-arc outer-arc_end-b" style={{ borderColor: color }} />
            <div className="outer-moon-a" style={{ background: color }} />
            <div className="outer-moon-b" style={{ background: color }} />
        </div>
    </SpinnerWrapper>
);

export default RotationSpinner;